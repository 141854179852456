import type { ReadConfig } from 'api-read-hook';
import type { HydraCollection } from '../../types';

import { useApiRead } from 'api-read-hook';
import { makeQueryString } from '../../util';

export type V_AdminDetail = {
  '@id': string;
  '@type': 'User';
  metaData: {
    '@id': string;
    '@type': 'UserMetaData';
    currentLocation: {
      '@id': string;
      '@type': 'Location';
      place: string;
    };
    isVeriffied: boolean;
    gender: string | null;
    ageRange: string | null;
    aboutYou: string | null;
    postcode: string | null;
    singleParentJourney: string | null;
  } | null;
  email: string | null;
  loginType: string | null;
  createdAt: number | null;
  lastActiveDate: string | null;
  datingIsProfileCompleted: boolean;
  communityUsername: string | null;
  communityProfileImage: string | null;
  isCommunityChampion: boolean;
  isCommunityChampionRestricted: boolean;
  firstName: string | null;
  lastName: string | null;
  datingProfileImage: string | null;
  hasCommunitySubscription: boolean;
  hasDatingSubscription: boolean;
  datingProfile: {
    '@id': string;
    '@type': 'DatingProfile';
    about: string | null;
    height: number | null;
    birthDate: string | null;
    imagePaths: Array<string> | null;
    heightInFeet: string;
    age: number | null;
  } | null;
  suspendedUser: {
    '@id': string;
    '@type': 'SuspendedUser';
    isCommunityBanned: boolean;
    isCommunitySuspended: boolean;
    isDatingBanned: boolean;
    isDatingSuspended: boolean | null;
  } | null;
  deviceMetaData: {
    '@id': string;
    '@type': 'DeviceMetaData';
    deviceId: string;
    appVersion: string;
    deviceType: string;
  } | null;
  onboardingAnswers: Array<{
    '@id': string;
    '@type': 'UserOnboardingAnswer';
    question: {
      '@id': string;
      '@type': 'OnboardingQuestion';
      id: number;
      name: string;
      sequence: number;
    };
    questionAnswer: {
      '@id': string;
      '@type': 'OnboardingQuestion';
      id: number;
      name: string;
      sequence: number;
    } | null;
    answerText: string | null;
  }>;
  parentingInfoMappings: Array<{
    '@id': string;
    '@type': 'UserParentingInfoMapping';
    parentingInfo: {
      '@id': string;
      '@type': 'UserMetaLegend';
      name: string;
    };
  }>;
  childInfos: Array<{
    '@id': string;
    '@type': 'ChildInfo';
    gender: string;
    dob: string;
  }>;
  notes: Array<{
    '@id': string;
    '@type': 'UserNote';
    body: string;
    createdAt: number;
    creatorName: string;
  }>;
  revenuecatEntitlements: Array<{
    '@id': string;
    '@type': 'RevenuecatEntitlement';
    type: string;
    startedAt: number;
    endedAt: number | null;
  }>;
  reportsReceived: Array<{
    '@id': string;
    '@type': 'ReportedEntity';
    entityType: string;
    reportReason: string | null;
    createdAt: number;
    reporterId: string | null;
    reporterEmail: string | null;
  }>;
  reportsCreated: Array<{
    '@id': string;
    '@type': 'ReportedEntity';
    entityType: string;
    reportReason: string | null;
    createdAt: number;
    reporterId: string | null;
    reporterEmail: string | null;
  }>;
  veriffSelfieImage: string | null;
};

export type V_AdminList = {
  '@id': string;
  '@type': 'User';
  metaData: {
    '@id': string;
    '@type': 'UserMetaData';
    isVeriffied: boolean;
    gender: string | null;
    ageRange: string | null;
    postcode: string | null;
    singleParentJourney: string | null;
  } | null;
  email: string | null;
  loginType: string | null;
  createdAt: number | null;
  lastActiveDate: string | null;
  communityUsername: string | null;
  communityProfileImage: string | null;
  firstName: string | null;
  lastName: string | null;
  datingProfileImage: string | null;
  hasCommunitySubscription: boolean;
  hasDatingSubscription: boolean;
  deviceMetaData: {
    '@id': string;
    '@type': 'DeviceMetaData';
    deviceId: string;
    appVersion: string;
    deviceType: string;
  } | null;
  veriffSelfieImage: string | null;
};

export type V_DetailCommunity = {
  '@id': string;
  '@type': 'User';
  id: string;
  metaData: {
    '@id': string;
    '@type': 'UserMetaData';
    currentLocation: {
      '@id': string;
      '@type': 'Location';
      place: string;
    };
    isVeriffied: boolean;
    gender: string | null;
    ageRange: string | null;
    aboutYou: string | null;
    postcode: string | null;
    singleParentJourney: string | null;
  } | null;
  lastActiveDate: string | null;
  communityUsername: string | null;
  communityProfileImage: string | null;
  myConnection: {
    '@id': string;
    '@type': 'UserConnection';
    status: string;
    connectionMessage: string | null;
    isSender: boolean;
    chat: string | null;
  } | null;
  parentingInfoMappings: Array<{
    '@id': string;
    '@type': 'UserParentingInfoMapping';
    parentingInfo: {
      '@id': string;
      '@type': 'UserMetaLegend';
      name: string;
    };
  }>;
  childInfos: Array<{
    '@id': string;
    '@type': 'ChildInfo';
    id: number;
    gender: string;
    dob: string;
  }>;
  isModerator: boolean;
};

export type V_DetailDating = {
  '@id': string;
  '@type': 'User';
  id: string;
  metaData: {
    '@id': string;
    '@type': 'UserMetaData';
    currentLocation: {
      '@id': string;
      '@type': 'Location';
      place: string;
    };
    isVeriffied: boolean;
    postcode: string | null;
  } | null;
  lastActiveDate: string | null;
  firstName: string | null;
  lastName: string | null;
  datingProfileImage: string | null;
  datingProfile: {
    '@id': string;
    '@type': 'DatingProfile';
    about: string | null;
    height: number | null;
    jobTitle: string | null;
    workInfo: {
      '@id': string;
      '@type': 'UserMetaLegend';
      id: number;
      name: string;
    } | null;
    birthDate: string | null;
    imagePaths: Array<string> | null;
    heightInFeet: string;
    age: number | null;
  } | null;
  myDatingLike: {
    '@id': string;
    '@type': 'DatingLike';
    status: string;
  } | null;
  theirDatingLike: {
    '@id': string;
    '@type': 'DatingLike';
    status: string;
  } | null;
  onboardingAnswers: Array<{
    '@id': string;
    '@type': 'UserOnboardingAnswer';
    question: {
      '@id': string;
      '@type': 'OnboardingQuestion';
      id: number;
      name: string;
      sequence: number;
    };
    questionAnswer: {
      '@id': string;
      '@type': 'OnboardingQuestion';
      id: number;
      name: string;
      sequence: number;
    } | null;
    answerText: string | null;
  }>;
  parentingInfoMappings: Array<{
    '@id': string;
    '@type': 'UserParentingInfoMapping';
    parentingInfo: {
      '@id': string;
      '@type': 'UserMetaLegend';
      id: number;
      name: string;
    };
  }>;
  childInfos: Array<{
    '@id': string;
    '@type': 'ChildInfo';
    id: number;
    gender: string;
    dob: string;
  }>;
  isModerator: boolean;
};

export type V_Discovery = {
  '@id': string;
  '@type': 'User';
  metaData: {
    '@id': string;
    '@type': 'UserMetaData';
    currentLocation: {
      '@id': string;
      '@type': 'Location';
      place: string;
    };
    isVeriffied: boolean;
  } | null;
  lastActiveDate: string | null;
  communityUsername: string | null;
  communityProfileImage: string | null;
  parentingInfoMappings: Array<{
    '@id': string;
    '@type': 'UserParentingInfoMapping';
    parentingInfo: {
      '@id': string;
      '@type': 'UserMetaLegend';
      id: number;
      name: string;
    };
  }>;
  childInfos: Array<{
    '@id': string;
    '@type': 'ChildInfo';
    gender: string;
    dob: string;
  }>;
};

export type V_MeetupAttendees = {
  '@id': string;
  '@type': 'User';
  metaData: {
    '@id': string;
    '@type': 'UserMetaData';
    isVeriffied: boolean;
  } | null;
  lastActiveDate: string | null;
  communityUsername: string | null;
  communityProfileImage: string | null;
};

export type V_MeetupSubTagSuggestions = {
  '@id': string;
  '@type': 'User';
  metaData: {
    '@id': string;
    '@type': 'UserMetaData';
    isVeriffied: boolean;
  } | null;
  lastActiveDate: string | null;
  communityUsername: string | null;
  communityProfileImage: string | null;
};

export type V_PostSubTagSuggestions = {
  '@id': string;
  '@type': 'User';
  metaData: {
    '@id': string;
    '@type': 'UserMetaData';
    isVeriffied: boolean;
  } | null;
  lastActiveDate: string | null;
  communityUsername: string | null;
  communityProfileImage: string | null;
};

export type W_AdminUpdate = {};

export type W_DatingEssentials = {
  firstName: string;
  lastName: string | null;
  birthDate: string;
  gender: string;
  height: number;
  parentingInfoIds: Array<number>;
  lat: number;
  long: number;
  place: string;
  postcode: string;
};

export type W_ManageSuspension = {
  isCommunitySuspended: boolean | null;
  isDatingSuspended: boolean | null;
  isCommunityBanned: boolean | null;
  isDatingBanned: boolean | null;
};

export type W_Update = {
  isDatingShow: boolean;
  mode: string | null;
  communityProfileImage: string | null;
  childInfos: Array<{
    gender: string;
    dob: string;
  }>;
};

export type W_UpdateCommunityBasicInfo = {
  communityUsername: string;
  ageRange: string;
  gender: string;
  singleParentJourney: string | null;
  postcode: string;
  aboutYou: string | null;
  parentingInfoIds: Array<number>;
  lat: number;
  long: number;
  place: string;
  isAnonymous: boolean;
};

export function useReadCollectionAdminList(
  queryParams?: {
    communityUsername?: string;
    'metaData.isVeriffSuspended'?: boolean;
    'order[communityUsername]'?: string;
    'order[createdAt]'?: string;
    'order[email]'?: string;
    'order[lastActiveDate]'?: string;
    search?: string;
    'suspendedUser.isCommunityBanned'?: boolean;
    'suspendedUser.isCommunitySuspended'?: boolean;
    'suspendedUser.isDatingBanned'?: boolean;
    'suspendedUser.isDatingSuspended'?: boolean;
    isCommunityChampion?: boolean;
  },
  options?: ReadConfig
) {
  return useApiRead<HydraCollection<V_AdminList>>(
    `/users/admin-list${makeQueryString(queryParams)}`,
    options
  );
}

export function useReadCollectionDatingSuggestionsnext(
  id: string,
  queryParams?: {
    communityUsername?: string;
    'metaData.isVeriffSuspended'?: boolean;
    'order[communityUsername]'?: string;
    'order[createdAt]'?: string;
    'order[email]'?: string;
    'order[lastActiveDate]'?: string;
    search?: string;
    'suspendedUser.isCommunityBanned'?: boolean;
    'suspendedUser.isCommunitySuspended'?: boolean;
    'suspendedUser.isDatingBanned'?: boolean;
    'suspendedUser.isDatingSuspended'?: boolean;
  },
  options?: ReadConfig
) {
  return useApiRead<HydraCollection<V_DetailDating>>(
    `/users/${id}/dating-suggestions/next${makeQueryString(queryParams)}`,
    options
  );
}

export function useReadCollectionFeed(
  queryParams?: {
    communityUsername?: string;
    'metaData.isVeriffSuspended'?: boolean;
    'order[communityUsername]'?: string;
    'order[createdAt]'?: string;
    'order[email]'?: string;
    'order[lastActiveDate]'?: string;
    search?: string;
    'suspendedUser.isCommunityBanned'?: boolean;
    'suspendedUser.isCommunitySuspended'?: boolean;
    'suspendedUser.isDatingBanned'?: boolean;
    'suspendedUser.isDatingSuspended'?: boolean;
  },
  options?: ReadConfig
) {
  return useApiRead<HydraCollection<V_Discovery>>(
    `/discovery/feed${makeQueryString(queryParams)}`,
    options
  );
}

export function useReadCollectionMeetupAttendeesSub(
  id: string,
  queryParams?: {
    communityUsername?: string;
    'metaData.isVeriffSuspended'?: boolean;
    'order[communityUsername]'?: string;
    'order[createdAt]'?: string;
    'order[email]'?: string;
    'order[lastActiveDate]'?: string;
    search?: string;
    'suspendedUser.isCommunityBanned'?: boolean;
    'suspendedUser.isCommunitySuspended'?: boolean;
    'suspendedUser.isDatingBanned'?: boolean;
    'suspendedUser.isDatingSuspended'?: boolean;
  },
  options?: ReadConfig
) {
  return useApiRead<HydraCollection<V_MeetupAttendees>>(
    `/meetups/${id}/attendees${makeQueryString(queryParams)}`,
    options
  );
}

export function useReadCollectionMeetupTagSuggestionsSub(
  id: string,
  queryParams?: {
    communityUsername?: string;
    'metaData.isVeriffSuspended'?: boolean;
    'order[communityUsername]'?: string;
    'order[createdAt]'?: string;
    'order[email]'?: string;
    'order[lastActiveDate]'?: string;
    search?: string;
    'suspendedUser.isCommunityBanned'?: boolean;
    'suspendedUser.isCommunitySuspended'?: boolean;
    'suspendedUser.isDatingBanned'?: boolean;
    'suspendedUser.isDatingSuspended'?: boolean;
  },
  options?: ReadConfig
) {
  return useApiRead<HydraCollection<V_MeetupSubTagSuggestions>>(
    `/meetups/${id}/tag-suggestions${makeQueryString(queryParams)}`,
    options
  );
}

export function useReadCollectionPostTagSuggestionsSub(
  id: string,
  queryParams?: {
    communityUsername?: string;
    'metaData.isVeriffSuspended'?: boolean;
    'order[communityUsername]'?: string;
    'order[createdAt]'?: string;
    'order[email]'?: string;
    'order[lastActiveDate]'?: string;
    search?: string;
    'suspendedUser.isCommunityBanned'?: boolean;
    'suspendedUser.isCommunitySuspended'?: boolean;
    'suspendedUser.isDatingBanned'?: boolean;
    'suspendedUser.isDatingSuspended'?: boolean;
  },
  options?: ReadConfig
) {
  return useApiRead<HydraCollection<V_PostSubTagSuggestions>>(
    `/post/${id}/tag-suggestions${makeQueryString(queryParams)}`,
    options
  );
}

export function useReadItemAdmin(id: string, options?: ReadConfig) {
  return useApiRead<V_AdminDetail>(`/users/${id}/admin`, options);
}

export function useReadItemCommunity(id: string, options?: ReadConfig) {
  return useApiRead<V_DetailCommunity>(`/users/${id}/community`, options);
}

export function useReadItemDating(id: string, options?: ReadConfig) {
  return useApiRead<V_DetailDating>(`/users/${id}/dating`, options);
}
