import type { FC } from 'react';
import ArchiveIcon from '@untitled-ui/icons-react/build/esm/Archive';
import ClipboardIcon from '@untitled-ui/icons-react/build/esm/Clipboard';
import Download01Icon from '@untitled-ui/icons-react/build/esm/Download01';
import FileCheck03Icon from '@untitled-ui/icons-react/build/esm/FileCheck03';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';

import { Button } from '@mui/material';
import { ChevronDown } from '@untitled-ui/icons-react';
import { usePopover } from '@mui-devias-kit/hooks/use-popover';

type Props = {
  options: Array<{ label: string; onClick: () => void }>;
};

export default function ButtonDropdown({ options }: Props) {
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      <Button
        endIcon={
          <SvgIcon>
            <ChevronDown />
          </SvgIcon>
        }
        ref={popover.anchorRef}
        variant="contained"
        onClick={popover.handleOpen}
      >
        Actions
      </Button>
      <Menu
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={popover.handleClose}
        open={popover.open}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 200,
          },
        }}
        // transformOrigin={{
        //   horizontal: 'right',
        //   vertical: 'top',
        // }}
      >
        {options.map((option) => (
          <MenuItem
            onClick={option.onClick}
            key={option.label}
            sx={{ whiteSpace: 'normal' }}
          >
            {/* <ListItemIcon>
            <SvgIcon>
              <Download01Icon />
            </SvgIcon>
          </ListItemIcon> */}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
        {/* <MenuItem onClick={popover.handleClose}>
          <ListItemIcon>
            <SvgIcon>
              <FileCheck03Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Export" />
        </MenuItem>
        <MenuItem onClick={popover.handleClose}>
          <ListItemIcon>
            <SvgIcon>
              <ClipboardIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Copy" />
        </MenuItem>
        <MenuItem onClick={popover.handleClose}>
          <ListItemIcon>
            <SvgIcon>
              <ArchiveIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Archive" />
        </MenuItem> */}
      </Menu>
    </>
  );
}
